<template>
  <!-- 动态详情 -->
  <div class="Dynamic_detail">
    <nav-bar title="动态详情" left-arrow @click-left="$router.history.go(-1)" />
    <div class="wrap">
      <!-- 动态 -->
      <div class="dynamic">
        <div class="list">
          <div class="box">
            <!-- 发布内容 -->
            <div class="top" @click.self="ipt_show = false">
              <div class="user" @click="ipt_show = false">
                <div class="name" @click="ipt_show = false">
                  <img :src="info.avatar == 'http://admin.wczstp.com' || info.avatar == '' ? require('@/assets/img/noface.png'):info.avatar" alt="" @click="ipt_show = false">
                  <span @click="ipt_show = false">{{info.user_name}}</span>
                </div>
                <div class="time">{{info.create_time}}</div>
              </div>
              <div class="info" @click="ipt_show = false">
                <div class="text">{{info.content}}</div>
                <div class="imgs">
                  <div v-for="(i,d) in info.imgs" :key="d" :style="{'height': height > 1000 ? '250px':'100px'}"><img :src="i" alt="" @click="big_src = i;big_show = true"></div>
                </div>
              </div>
              <div class="comment">
                <img src="@/assets/img/like.png" alt="" @click="zan(info.id,$event)" v-if="info.is_like != 1" id="like">
                <img src="@/assets/img/like_select.png" alt="" v-else>
                <span>{{info.give_num == 0 ? '':info.give_num}}</span>
                <img src="@/assets/img/comment.png" alt="" @click="comment(info.id)" style="width: 20px;height: 20px">
              </div>
              <!-- 评论 -->
              <div class="bottom">
                <div v-for="(j,d) in info.comments_list" :key="d">
                  <span class="name">{{j.user_name}}：</span>
                  <span class="text">{{j.content}}</span>
                </div>
              </div>
            </div>
            <div class="more" @click="toDetail1(info.id)" v-if="info.comments_num != 0">
              <span>共{{info.comments_num}}条回复</span>
              <span>点击查看<i></i></span>
            </div>
          </div>
        </div>
      </div>
      <!-- 评论 -->
      <div class="ipt" v-show="ipt_show"><input type="text" name="" id="" placeholder="输入你想要发布的内容吧~" v-model="text"><span @click="send">发送</span></div>
      <!-- 放大图片 -->
      <div class="big_img" @click="big_show = false" v-if="big_show">
        <img :src="big_src" alt="">
      </div>
    </div>
    <div class="wechat_popup" @click.self="hide_wechat">
      <div class="box">
        <div class="title">分享至</div>
        <div class="imgs">
          <img src="@/assets/img/haoyou.png" alt="" @click="share(1)">
          <img src="@/assets/img/pengyouquan.png" alt="" @click="share(0)">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { NavBar } from "vant";

export default {
  components: {
    NavBar,
  },
  data() {
    return {
      headerName: '动态详情',
      msg: '', // 提示内容
      showAlert: false, // 提示显示
      ifload: false, // loading
      ipt_show: false, // 评论框显示
      text: '', // 评论输入框内容
      textid: '', // 动态id
      height: '',
      big_src: '',
      big_show: false,
      unset: false,
      nodata: false,
      info: {}
    }
  },
  created () {
    if (this.$route.query.token) {
      localStorage.setItem('token',this.$route.query.token)
    }
  },
  mounted () {
    this.id = this.$route.query.id;
    this.height = document.documentElement.clientHeight;
    // 动态初始数据
    this.getdongtai()
    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf('micromessenger') != -1;
    if (isWeixin) {
      $('.header_left').hide()
    } else {
      $('.header_left').show()
    }
    // 获取用户信息
    this.$get('/User/index',{},(res)=>{
      if (res.data.code == 1) {
        this.user = res.data.data;
      }
    })
  },
  methods: {
    // 显示分享
    show_share () {
      $('.wechat_popup').css('display','block')
      $('.wechat_popup > .box').animate({
        bottom: 0
      })
    },
    // 隐藏微信分享
    hide_wechat () {
      $('.wechat_popup > .box').animate({
        bottom: -120+'px'
      })
      setTimeout(()=>{
        $('.wechat_popup').css('display','none')
      },400)
    },
    // 分享
    share (type) {
      // if (/LT-APP/.test(navigator.userAgent)) {
      //   this.$post('/user/videoShareScore',{},(res)=>{
      //     jsBridge.share({
      //       //0 微信朋友圈
      //       //1 微信好友
      //       //2 QQ好友
      //       //3 QQ空间
      //       to: type,
      //       title : '动态',
      //       link  : location.href + `&invite_code=${this.user.invite_code}`,
      //       imgUrl: '../../assets/img/logo@2x.png',
      //       desc : this.info.content
      //     })
      //   })
      // }
    },
    // 评论
    comment (id) {
      this.textid = id;
      this.ipt_show = true;
    },
    // 动态数据列表
    getdongtai () {
      this.$post('/culture/getdynamicDetail',{did: this.id},(res)=>{
        if (res.data.code == 1) {
          this.info = res.data.data;
        } else {
          this.mgs = res.data.msg;
          this.showAlert = true;
        }
      })
    },
    // 点赞
    zan (id,e) {
      $(e.currentTarget).attr('src',require('@/assets/img/like_select.png'))
      this.$post('/culture/dynamicGive',{did: id},(res)=>{})
    },
    // 评价发送
    send () {
      if (this.text == '') {
        this.msg = '请输入内容';
        this.showAlert = true;
        return;
      }
      this.$post('/culture/dynamicComment',{did: this.textid,pid: 0,content: this.text},(res)=>{
        if (res.data.code == 1) {
          this.msg = '发送成功';
          this.showAlert = true;
          this.ipt_show = false;
          this.text = '';
          this.getdongtai()
        } else {
          this.msg = res.data.msg;
          this.showAlert = true;
          this.ipt_show = false;
          this.text = '';
        }
      })
    },
    // 评论详情
    toDetail1 (id) {
      this.$router.push({name: 'comment_detail',query: {id}})
    }
  }
}
</script>

<style lang='scss' scoped>
.Dynamic_detail {
  width: 100%;
  height: 100vh;
  .release {
    position: fixed;
    right: 0;
    bottom: 100px;
    width: 80px;
    z-index: 2;
    background: #AC0404;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
    border-radius: 50px 0 0 50px;
    img {
      width: 30px;
      margin-left: 10px;
    }
    span {
      color: white;
      margin-left: 10px;
      font-size: 12px;
    }
  }
  .wrap {
    width: 100%;
    height: 100%;
    background: #F2ECD4;
    padding-top: 20px;
    // 动态
    .dynamic {
      padding: 0 10px;
      .list {
        width: 100%;
        .box {
          width: 100%;
          .top {
            position: relative;
            padding: 10px;
            border-radius: 10px;
            background: white;
            .user {
              display: flex;
              justify-content: space-between;
              .name {
                display: flex;
                align-items: center;
                img {
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
                  margin-right: 5px;
                }
                span {
                  font-size: 14px;
                  color: #646464;
                }
              }
              .time {
                font-size: 12px;
                color: #646464;
              }
            }
            .info {
              width: 100%;
              .text {
                margin: 10px 0;
                font-size: 14px;
                font-weight: bold;
                word-break: break-all;
              }
              .imgs {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                div {
                  width: 33.33%;
                  height: 100px;
                  padding: 2px;
                  box-sizing: border-box;
                  img {
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                    margin-bottom: 4px;
                  }
                }
              }
            }
            .comment {
              width: 70px;
              display: flex;
              justify-content: space-between;
              float: right;
              margin-top: 10px;
              img {
                width: 20px;
                height: 20px;
              }
              span {
                font-size: 14px;
                color: #ff5555;
              }
            }
          }
          // 评论
          .bottom {
            width: 100%;
            background: #eeeeee;
            margin-top: 40px;
            div {
              display: flex;
              padding: 10px;
              span {
                display: inline-block;
              }
              .name {
                font-size: 14px;
                color: #646464;
              }
              .text {
                width: 250px;
                font-size: 12px;
                color: #646464;
                word-break: break-all;
                margin-top: 2px;
              }
            }
          }
          .more {
            display: flex;
            justify-content: space-between;
            width: 100%;
            font-size: 14px;
            background: white;
            color: #646464;
            padding: 5px 20px;
            box-sizing: border-box;
            span {
              display: flex;
              align-items: center;
              i {
                display: inline-block;
                width: 10px;
                height: 10px;
                border-right: 1px solid #999;
                border-bottom: 1px solid #999;
                transform: rotate(-45deg);
              }
            }
          }
        }
      }
    }
    // 评论输入框
    .ipt {
      position: fixed;
      bottom: 0;
      width: 100%;
      height: 60px;
      background: white;
      display: flex;
      justify-content: space-around;
      align-items: center;
      input {
        width: 70%;
        height: 50%;
        border: none;
        outline: none;
        background: #eeeeee;
        border-radius: 20px;
        padding-left: 20px;
        font-size: 15px;
      }
      input::-webkit-input-placeholder{
        color: #A2A2A2;
      }
      span {
        padding: 5px 10px;
        background: #AB0404;
        color: white;
        font-size: 14px;
        border-radius: 20px;
      }
    }
    // 放大图片
    .big_img {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 999;
      background: rgba($color: #000000, $alpha: .7);
      img {
        width: 100%;
      }
    }
  }
}
.wechat_popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, .7);
  .box {
    position: absolute;
    bottom: -150px;
    background: white;
    width: 100%;
    height: 150px;
    border-radius: 10px 10px 0 0;
    .title {
      text-align: center;
      font-size: 16px;
      margin-top: 10px;
    }
    .imgs {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 20px;
      img {
        width: 50px;
        height: 60px;
      }
    }
  }
}
.share {
  position: fixed;
  top: 14px;
  right: 10px;
  z-index: 2;
  font-size: 16px;
  font-weight: bold;
  img {
    width: 20px;
  }
}
</style>